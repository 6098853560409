import React from 'react'

import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'

import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Image from '@/components/base/image'
import IconList, { IconListItem } from '@/components/base/icon-list'

import { FooterProps } from './type'
import { classnames } from 'begonya/functions'

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  const app = useApp()
  const width = useWidth()

  return (
    <footer className="footer">
      <Container size="medium">
        <Row className="footer-top-wrapper">
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 3 }}
            xl={{ size: 3 }}
          >
            <Anchor
              className="footer-top-wrapper-logo"
              href={app.settings.homepage}
            >
              <Image
                {...(props.logo?.src ? props.logo : app.theme.logo)}
                alt={app.page.title}
                height={50}
              />
            </Anchor>

            <div className="title">Çağrı Merkezi</div>
            <a href={`tel:${props.phone}`}>
              <b>{props.phone}</b>
            </a>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <nav className="footer-top-wrapper-menu">
              <ul>
                {props.navigation?.map((menuItem, index) => (
                  <li
                    key={index}
                    className={classnames(
                      menuItem.className,
                      menuItem.children.length && 'hasSubMenu'
                    )}
                  >
                    {menuItem.children.length ? (
                      <Anchor onClick={(e) => e.preventDefault()}>
                        {menuItem.title}
                      </Anchor>
                    ) : (
                      <Anchor
                        href={menuItem.href}
                        dangerouslySetInnerHTML={{
                          __html: menuItem.title,
                        }}
                      />
                    )}
                    {menuItem.children.length ? (
                      <ul className="sub-menu">
                        {menuItem.children.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Anchor href={subItem.href}>{subItem.title}</Anchor>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 3 }}
            xl={{ size: 3 }}
          >
            <div className="footer-top-wrapper-contacts">
              {props.contacts?.map((item, index) => (
                <div key={index} className="contacts-item">
                  <div className="title">{item.title}</div>
                  <span>{item.address}</span>
                  <a href={`tel:${item.phone}`}>{item.phone}</a>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </div>
              ))}
            </div>
            <div className="footer-top-wrapper-social-media">
              <IconList className="footer-social-media-list">
                {app.settings.socials['facebook'] && (
                  <IconListItem
                    icon={{
                      name: 'icon-facebook',
                      onClick: () =>
                        window.open(
                          app.settings.socials['facebook'] ||
                            'https://www.facebook.com/asggrup',
                          '_blank'
                        ),
                      style: { cursor: 'pointer' },
                    }}
                  />
                )}
                {app.settings.socials['twitter'] && (
                  <IconListItem
                    icon={{
                      name: 'icon-twitter',
                      onClick: () =>
                        window.open(
                          app.settings.socials['twitter'] ||
                            'https://www.twitter.com/asggrup',
                          '_blank'
                        ),
                      style: { cursor: 'pointer' },
                    }}
                  />
                )}
                {app.settings.socials['instagram'] && (
                  <IconListItem
                    icon={{
                      name: 'icon-instagram',
                      onClick: () =>
                        window.open(
                          app.settings.socials['instagram'] ||
                            'https://www.instagram.com/asggrup/',
                          '_blank'
                        ),
                      style: { cursor: 'pointer' },
                    }}
                  />
                )}
                {app.settings.socials['youtube'] && (
                  <IconListItem
                    icon={{
                      name: 'icon-youtube',
                      onClick: () =>
                        window.open(
                          app.settings.socials['youtube'] ||
                            'https://www.linkedin.com/asggrup',
                          '_blank'
                        ),
                      style: { cursor: 'pointer' },
                    }}
                  />
                )}
                {app.settings.socials['linkedin'] && (
                  <IconListItem
                    icon={{
                      name: 'icon-linkedin2',
                      onClick: () =>
                        window.open(
                          app.settings.socials['linkedin'] ||
                            'https://www.linkedin.com/asggrup',
                          '_blank'
                        ),
                      style: { cursor: 'pointer' },
                    }}
                  />
                )}
              </IconList>
            </div>
          </Column>
        </Row>
      </Container>

      <Container size="medium">
        <Row className="footer-bottom-wrapper">
          <Column
            className="footer-bottom-wrapper-copyright"
            xs={{ size: 12, order: 'last' }}
            sm={{ size: 12, order: 'last' }}
            md={{ size: 6, order: 'first' }}
            lg={{ size: 6, order: 'first' }}
            xl={{ size: 6, order: 'first' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['designby'],
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['copyright'],
              }}
            />
          </Column>
          <Column
            className="footer-bottom-wrapper-policy-menu"
            xs={{ size: 12, order: 'first' }}
            sm={{ size: 12, order: 'first' }}
            md={{ size: 6, order: 'last' }}
            lg={{ size: 6, order: 'last' }}
            xl={{ size: 6, order: 'last' }}
          >
            <ul>
              {props?.policyNavigation?.map((menuItem, index) => (
                <li key={index} className={menuItem.className}>
                  {menuItem.href.indexOf('http') === 0 ? (
                    <a
                      href={menuItem.href}
                      target={menuItem.target}
                      dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      rel="noreferrer"
                    />
                  ) : (
                    <Anchor
                      href={menuItem.href}
                      dangerouslySetInnerHTML={{ __html: menuItem.title }}
                    />
                  )}
                </li>
              ))}
            </ul>
          </Column>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
